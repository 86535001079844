import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import { Container, Row, Col } from "react-bootstrap"
import AppStore from "../../images/image-app-store.svg"
import PlayStore from "../../images/image-play-store.svg"
import CircleHalf from "../../images/bg-circle-half.svg"
import TiltedPhone from "../../images/tilted-phone.png"

function Download({
  props,
  propsAbout,
  propsSendMoney,
  propsReferral,
  isDownloadTitle = false,
}) {
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const { Title1, Desc } = props || {}
  const { Title: titleAbout } = propsAbout || {}
  const { Title: titleSendMoney } = propsSendMoney || {}
  const { Title: titleReferral, Desc: descReferral } = propsReferral || {}

  const isAboutUs = location.pathname.includes("/about-us")
  const isReferral = location.pathname.includes("/referral")
  const isSendMoney = location.pathname.includes("/send-money")
  const isSwiftCode = location.pathname.includes("/swift")
  const isSwiftCheck = location.pathname.includes("/bic")

  const LinkHrefDecider = () => {
    if (isSwiftCode)
      return {
        appstore: "https://transfez.onelink.me/e9Ud/web4a?page=send",
        playstore: "https://transfez.onelink.me/e9Ud/web4b?page=send",
      }
    if (isSwiftCheck)
      return {
        appstore: "https://transfez.onelink.me/e9Ud/web5a?page=send",
        playstore: "https://transfez.onelink.me/e9Ud/web5b?page=send",
      }
    if (isAboutUs)
      return {
        appstore: "https://transfez.onelink.me/e9Ud/web3a?page=send",
        playstore: "https://transfez.onelink.me/e9Ud/web3b?page=send",
      }
    return {
      appstore: "https://transfez.onelink.me/e9Ud/web1a?page=send",
      playstore: "https://transfez.onelink.me/e9Ud/web1b?page=send",
    }
  }

  const TitleDecider = () => {
    if (isAboutUs)
      return {
        title: titleAbout,
      }
    if (isSendMoney)
      return {
        title: titleSendMoney,
      }
    if (isReferral)
      return {
        title: titleReferral,
        desc: descReferral,
      }
    return {
      title: Title1,
      desc: Desc,
    }
  }

  const { title, desc } = TitleDecider()
  const { appstore, playstore } = LinkHrefDecider()
  const DownloadTitle = isEnglish ? "Download Now" : "Download Sekarang"

  return (
    <MainContainer
      ptop={isReferral && "100px"}
      pbottom={(isReferral || isSwiftCode) && "150px"}
    >
      <MainDiv mbottom={isDownloadTitle && "50px"}>
        <MainTitle>{title}</MainTitle>
        {desc && <MainDesc>{desc}</MainDesc>}
      </MainDiv>
      {isDownloadTitle && <DownloadH5>{DownloadTitle}</DownloadH5>}
      <DownloadDiv mtop={isDownloadTitle && "0px"}>
        <a href={appstore} target="_blank">
          <Imgstyled src={AppStore} alt="appstore" />
        </a>
        <a href={playstore} target="_blank">
          <Imgstyled src={PlayStore} alt="playstore" />
        </a>
      </DownloadDiv>
    </MainContainer>
  )
}

export default Download

const MainContainer = styled(Container)`
  text-align: center;
  padding-top: ${p => p.ptop || "150px"};
  padding-bottom: ${p => p.pbottom || "100px"};
  @media (max-width: 576px){
    padding-top: 50px;
  }
`

const MainDiv = styled.div`
  text-align: center;
  padding: 0px 15%;
  margin-bottom: ${p => p.mbottom};
  @media (max-width: 992px) {
    padding: 0px 5%;
  }
`

const MainTitle = styled.h2`
  font-family: "Typefez-black";
  font-size: 42px;
  color: #102c57;
  margin-bottom: 1rem;
  @media (max-width: 576px) {
    font-size: 28px;
  }
`

const MainDesc = styled.h5`
  font-family: "Typefez-reg";
  color: #102c57;
  font-size: 18px;
`

const DownloadDiv = styled.div`
  margin-top: ${p => p.mtop || "30px"};
  display: inline-block;
  flex-wrap: nowrap;
  background-color: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 20px;
`

const DownloadH5 = styled.p`
  font-family: "Typefez-reg";
  color: #102c57;
  font-size: 18px;
`

const Imgstyled = styled.img`
  width: 110px;
  margin: 0px 6px;
`
