import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { CustomDot } from "../Reusable/dot-line"
import { DotAccordion } from "./DotAccordion"
import { IdComponent } from "../Reusable/id-components"
import currencyRightImg from "../../images/currency-coin-right.png"
import currencyLeftImg from "../../images/currency-coin-left.png"
import bgGreyRotate from "../../images/logo-big-grey-rotate.svg"
import { useLocation } from "@reach/router"

export const HowToUse = ({ props }) => {
  const data = useStaticQuery(graphql`
    query {
      CK1: file(relativePath: { eq: "send-money-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CK2: file(relativePath: { eq: "send-money-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CK3: file(relativePath: { eq: "send-money-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CK4: file(relativePath: { eq: "send-money-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CKE1: file(relativePath: { eq: "send-money-en-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CKE2: file(relativePath: { eq: "send-money-en-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CKE3: file(relativePath: { eq: "send-money-en-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      CKE4: file(relativePath: { eq: "send-money-en-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  const {
    Title,
    Point1,
    Desc1,
    Point2,
    Desc2,
    Point3,
    Desc3,
    Point4,
    Desc4,
  } = props
  const DataList = [
    {
      eventId: "0",
      name: Point1,
      content: Desc1,
    },
    {
      eventId: "1",
      name: Point2,
      content: Desc2,
    },
    {
      eventId: "2",
      name: Point3,
      content: Desc3,
    },
    {
      eventId: "3",
      name: Point4,
      content: Desc4,
    },
  ]

  const Img1 = isEnglish
    ? data.CKE1.childImageSharp.fluid
    : data.CK1.childImageSharp.fluid
  const Img2 = isEnglish
    ? data.CKE2.childImageSharp.fluid
    : data.CK2.childImageSharp.fluid
  const Img3 = isEnglish
    ? data.CKE3.childImageSharp.fluid
    : data.CK3.childImageSharp.fluid
  const Img4 = isEnglish
    ? data.CKE4.childImageSharp.fluid
    : data.CK4.childImageSharp.fluid

  const [activeId, setActiveId] = useState("0")
  const [activeImg, setActiveImg] = useState(Img1)

  const handleImg = e => {
    setActiveId(e)
  }

  useEffect(() => {
    if (activeId === "0") setActiveImg(Img1)
    if (activeId === "1") setActiveImg(Img2)
    if (activeId === "2") setActiveImg(Img3)
    if (activeId === "3") setActiveImg(Img4)
    return
  }, [activeId])
  return (
    <>
      <IdComponent title="how-to" />
      <MainContainer>
        <CustomTitle>
          {Title}
          {/* <CustomDot /> */}
        </CustomTitle>
        <Row>
          <Col md={8}>
            <MainDiv pad="40px 30px 30px 30px">
              <DotAccordion
                data={DataList}
                isHowToUse
                sendToParent={handleImg}
              />
              <BackgroundImg src={bgGreyRotate} alt="logo" />
            </MainDiv>
          </Col>
          <Col md={4}>
            {/* <CustomYellow /> */}
            <CustomImg fluid={activeImg} alt="how to use" fadeIn loading="eager"/>
          </Col>
        </Row>
        <CurrencyImg
          alt="currency"
          src={currencyLeftImg}
          top="70px"
          left="-200px"
        />
        <CurrencyImg
          alt="currency"
          src={currencyRightImg}
          top="70px"
          right="-200px"
        />
      </MainContainer>
    </>
  )
}

const CustomTitle = styled.h2`
  font-size: 50px;
  color: #102c57;
  font-family: "Typefez-extrabold";
  @media (max-width: 768px) {
    font-size: 36px;
  }
`

const MainContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  @media (max-width: 576px) {
    padding-bottom: 50px;
  }
`

const MainDiv = styled.div`
  box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  -webkit-box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  -moz-box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  border-radius: 15px;
  margin: 15px;
  padding: ${p => p.pad};
  margin: 50px 0px 10px 0px;
  background-color: white;
  /* position: relative;
  z-index: 50; */
  @media (max-width: 576px) {
    width: 90%;
  }
`

const CustomImg = styled(Img)`
  width: 70%;
  @media (max-width: 768px) {
    display: none;
  }
`

const CustomYellow = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: #ffd27c;
  border-radius: 50%;
  top: 40%;
  @media (max-width: 768px) {
    display: none;
  }
`

const CurrencyImg = styled.img`
  position: absolute;
  left: ${p => p.left};
  right: ${p => p.right};
  top: ${p => p.top};
  @media (max-width: 768px) {
    display: none;
  }
`

export const BackgroundImg = styled.img`
  position: absolute;
  top: ${p => p.top || "-500px"};
  left: ${p => p.left || "500px"};
  z-index: -5;
  min-width: ${p => p.width || "1000px"};
  transform: rotate(${p => p.rotate});
  @media (max-width: 768px) {
    display: none;
  }
`
